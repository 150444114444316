// eslint-disable-next-line no-unused-vars
var AutocompleteInitializer = {
  initialize: function (id, repo, resultClass, onSelectCallback, itemRenderCallback, institutionCallback) {
    if (typeof resultClass !== 'undefined') {
      this.clearIdFieldValue(id, resultClass)
    }
    this.autocomplete(id, repo, onSelectCallback, resultClass, this.getActionList(repo, institutionCallback), itemRenderCallback)
  },
  getActionList: function (repo, institutionCallback) {
    let actionList = {}
    if (repo === 'institutions') {
      actionList = { '-1': ['+ Add new institution', typeof institutionCallback !== 'undefined' ? institutionCallback : 'addNewInstitution'] }
    }
    return actionList
  },
  clearIdFieldValue: function (id, resultClass) {
    let field = ''
    $('#' + id).on('input', function (e) {
      field = $(e.target).parents('form').find('.' + resultClass)
      if (field.val() > 0) {
        field.val(0)
      }
    })
  },
  autocomplete: function (id, repo, onSelectCallback, resultClass, actionList, itemRenderCallback) {
    $.each($(`input[id^=${id}][type=text]`), function (key, elem) {
      Autocompletes[repo + '_' + key] = new Autocomplete(
        $(elem),
        '/profile/searchterm/' + repo + '/',
        onSelectCallback,
        $(elem).parents('form').find('.' + resultClass),
        actionList,
        itemRenderCallback
      )
    })
  }
}
